<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách đơn hàng thường</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary md-search">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="search.customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="search.contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts">
                                    <label>Tất cả hợp đồng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.contractName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openContract()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hợp đồng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="search.staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs">
                                    <label>Nhân viên</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo mã đơn hàng</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo booking/bill/tờ khai</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="planId">Loại hàng</label>
                                <md-select v-model="search.planId" name="planId" id="planId">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option v-for="b in planList" :key="b.id" :value="b.id">{{b.text}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 326px); overflow-x: auto; width: 100%;">
                        <table class="main-table" style="width: 2670px;">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="sticky-col" style="width:50px; background-color: #FFF0DC;">#</th>
                                    <th rowspan="2" class="sticky-col-2" style="width:120px; background-color: #FFF0DC;">Hành động</th>
                                    <th rowspan="2" class="left sticky-col-3" style="width:200px; background-color: #FFF0DC;">Mã đơn hàng</th>
                                    <th rowspan="2" class="left" style="width:200px; background-color: #FFF0DC;">Khách hàng</th>
                                    <th rowspan="2" class="center sticky-col-4" style="width:200px; background-color: #FFF0DC;">Bill/booking</th>
                                    <th rowspan="2" class="center" style="width:150px; background-color: #FFF0DC;">Số tờ khai</th>
                                    <th rowspan="2" class="center" style="width:150px; background-color: #FFF0DC;">Hải quan</th>

                                    <th rowspan="2" class="center" style="width:100px; background-color: #FFF0DC">Dự toán</th>
                                    <th rowspan="2" class="center" style="width:100px; background-color: #FFF0DC">Phải thu</th>
                                    <th rowspan="2" class="center" style="width:100px; background-color: #FFF0DC">Phải trả</th>

                                    <th colspan="4" class="center" style="width:120px; background-color: #FFD3B6;">Phân bổ</th>
                                    <th colspan="4" class="center" style="width:120px; background-color: #8DECB4;">PPL điều xe</th>
                                    <th rowspan="2" class="left" style="width:170px; background-color: #FFF0DC">Hợp đồng</th>
                                    <th rowspan="2" class="left" style="width:200px; background-color: #FFF0DC">Nhân viên</th>
                                    <th rowspan="2" class="left" style="width:250px; background-color: #FFF0DC">Chi nhánh</th>
                                    <th rowspan="2" style="width:100px; background-color: #FFF0DC">Ngày tạo</th>
                                </tr>
                                <tr>
                                    <th class="center" style="width:120px;background-color: #FFD3B6;">SL con't/kiện hàng</th>
                                    <th class="center" style="width:100px;background-color: #FFD3B6;">PPL</th>
                                    <th class="center" style="width:100px;background-color: #FFD3B6;">Vendor</th>
                                    <th class="center" style="width:150px;background-color: #FFD3B6;">Trạng thái</th>
                                    <th class="center" style="width:100px; background-color: #8DECB4;">Phân bổ</th>
                                    <th class="center" style="width:100px; background-color: #8DECB4;">Điều xe</th>
                                    <th class="center" style="width:100px; background-color: #8DECB4;">Quyết toán</th>
                                    <th class="center" style="width:130px; background-color: #8DECB4;">Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center sticky-col">{{index + 1}}</td>
                                    <td class="action sticky-col-2" style="width:120px;">
                                        <router-link :to="'/order/crud/' + item.id">
                                            <md-button class="md-fab md-mini md-second">
                                                <md-icon>edit</md-icon>
                                                <md-tooltip>Sửa</md-tooltip>
                                            </md-button>
                                        </router-link>
                                        <md-button v-if="item.status == 0" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                            <md-icon>clear</md-icon>
                                            <md-tooltip>Xóa</md-tooltip>
                                        </md-button>
                                        <md-button v-else :disabled="true" class="md-fab md-mini md-plain">
                                            <md-icon>clear</md-icon>
                                            <md-tooltip>Xóa</md-tooltip>
                                        </md-button>
                                    </td>
                                    <td class="left sticky-col-3">{{item.orderCode}}</td>
                                    <td class="left">{{item.customer.companyAlias}}</td>
                                    <td class="left sticky-col-4">{{item.booking.noBooking}} </td>
                                    <td class="left">{{item.customs.declarationCode}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.customs.status == 0" class="status status-temp">Chưa mở tờ khai</md-chip>
                                        <md-chip v-else-if="item.customs.status == 1" class="status status-inprogress">Mở tờ khai</md-chip>
                                        <md-chip v-else-if="item.customs.status == 2" class="status status-green-accent">Phân luồng tờ khai</md-chip>
                                        <md-chip v-else-if="item.customs.status == 3" class="status status-violet">Đăng ký hải quan</md-chip>
                                        <md-chip v-else-if="item.customs.status == 4" style="background-color: #95D2B3;" class="status">Không hải quan</md-chip>
                                        <md-chip v-else-if="item.customs.status == 5" class="status status-active">Thông quan</md-chip>
                                        <md-chip v-else-if="item.customs.status == 6" style="background-color: #DA7297;" class="status">Mang hàng về bảo quản</md-chip>
                                        <md-chip v-else-if="item.customs.status == 7" style="background-color: #48CFCB;" class="status">Giải phóng hàng</md-chip>
                                    </td>

                                    <td class="right">{{item.numberOrderEstimate}}</td>
                                    <td class="right">{{item.numberRevenue}}</td>
                                    <td class="right">{{item.numberRefund}}</td>

                                    <td class="right">{{item.numberDetail}}</td>
                                    <td class="right">{{item.numberDistributePpl}}</td>
                                    <td class="right">{{item.numberDistributeVendor}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.numberDistributePpl == 0 && item.numberDistributeVendor == 0" style="width: 110px;" class="status status-temp">Chưa phân bổ</md-chip>
                                        <md-chip v-else-if="item.numberDetail > (item.numberDistributePpl + item.numberDistributeVendor)" style="width: 110px;" class="status status-violet">Đang phân bổ</md-chip>
                                        <md-chip v-else-if="item.numberDetail == (item.numberDistributePpl + item.numberDistributeVendor)" style="width: 110px;" class="status status-active">Đã phân bổ</md-chip>
                                    </td>
                                    <td class="right">{{item.numberDistributePpl}}</td>
                                    <td class="right">{{item.numberJob}}</td>
                                    <td class="right">{{item.numberInvoice}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.numberDistributePpl > 0 && item.numberJob == 0" style="width: 100px;" class="status status-temp">Chưa điều xe</md-chip>
                                        <md-chip v-else-if="item.numberDistributePpl > item.numberJob" style="width: 100px;" class="status status-inprogress">Đang điều xe</md-chip>
                                        <md-chip v-else-if="item.numberDistributePpl == item.numberJob && item.numberJob > 0" style="width: 100px;" class="status status-active">Đã điều xe</md-chip>
                                        <md-chip v-else style="width: 100px;" class="status status-temp">Chưa điều xe</md-chip>
                                    </td>
                                    <td class="left">{{item.contract.contractCode}}</td>
                                    <td class="left">{{item.staff.fullName}}</td>
                                    <td class="left">{{item.branch.branchName}}</td>
                                    <td class="center">{{item.orderDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 0">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <contractList ref="contractList" @close="closeContract"/>
    </div>
</template>
<script>
    import staffService from '../../../api/staffService';
    import orderService from '../../../api/orderService';
    import contractService from '../../../api/contractService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import staffList from '../../../components/popup/_StaffList.vue';
    import branchService from '../../../api/branchService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';

    export default {
        components: {
            staffList,
            customerList,
            contractList,
        },
        metaInfo: {
            title: 'Danh sách đơn hàng thường'
        },
        data() {
            return {
                loadding: false,
                planList: common.planOfTransport,
                search: { pageIndex: 1, pageSize: common.pageSize, customerName: '', branchId: 0, planId: 0, fromDate: this.getRangeDate(-7), toDate: common.dateNow, contractId: 0, customerId: 0, code: '', keyword: '', staffId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                customerType: common.companyType.customer,
                contracts: [],
                contractName: '',
                customers: [],
                customerName: '',
                staffName: '',
                staffs: [],
                branchs: []
            }
        },
        created(){
            const searchHistory = localStorage.getItem('search-order');
            if(searchHistory !== '' && searchHistory !== null){
                this.search = JSON.parse(searchHistory);
            }
            this.rowPerPageOptions = common.rowPerPageOptions;
            this.getContracts();
            this.getCustomers();
            this.getBranchs();
            this.getByAccount();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }
                localStorage.setItem('search-order', JSON.stringify(this.search));
                this.filter();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.search.branchId = response.data.department.branchId;
                            this.filter();
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Staff
            closeStaff(item){
                this.search.staffName = item.fullName;
                this.search.staffId = item.id;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.search.staffId = val.id;
                this.search.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 100, companyType: common.companyType.customer, alias: searchTerm };
                companyService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeCustomer(item){
                this.search.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(item){
                this.search.customerName = item.companyAlias;
                this.search.customerId = item.id;
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 100, name: searchTerm };
                contractService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeContract(item){
                this.search.contractName = item.contractName;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(item){
                this.search.contractName = item.contractName;
                this.search.contractId = item.id;
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.contractId = 0;
                this.search.customerId = 0;
                this.search = { pageIndex: 1, pageSize: common.pageSize, branchId: 0, fromDate: this.getRangeDate(-7), toDate: common.dateNow, contractId: 0, customerId: 0, code: '', keyword: '', staffId: 0 };
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/order?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/order?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.isHeavy = false;
                orderService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                orderService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/order/crud');
            }        
        },
        watch: {
            'search.staffName': function (val) {
                if(val == ''){
                    this.search.staffId = 0;
                }
            },
            'search.contractName': function (val) {
                if(val == ''){
                    this.search.contractId = 0;
                }
            },
            'search.customerName': function (val) {
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>

<style scoped>
    tr th {
        z-index: 10;
    }
    tr th.sticky-col, th.sticky-col-2, th.sticky-col-3, th.sticky-col-4 {
        z-index: 9999;
    }
    tr td.sticky-col, td.sticky-col-2, td.sticky-col-3, td.sticky-col-4 {
        z-index: 9998;
    }
    .sticky-col {
        position: sticky !important;
        left: 0 !important;
        z-index: 9999;
    }
    .sticky-col-2 {
        position: sticky !important;
        left: 30px !important;
        z-index: 9999;
    }
    .sticky-col-3 {
        position: sticky !important;
        left: 138px !important;
        z-index: 9999;
    }
    .sticky-col-4 {
        position: sticky !important;
        left: 317px !important;
        z-index: 9999;
    }

    ::-webkit-scrollbar{
        height: 12px;
        width: 0;
        background: #FFF;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #e0e0e0;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

</style>
